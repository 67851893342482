import { IRR } from './money';

export const calculateMonthlyPayment = (
  amountToFinance: number,
  discountedAmount: number,
  residualPercentage: number,
  upfrontPaymentAmount: number,
  months: number,
  targetIRR = 30, // Default to 30 but now configurable
): number => {
  let low = 0;
  let high = amountToFinance * 2; // Set a reasonable upper bound
  let bestMonthlyPayment = 0;
  let bestDiff = Infinity;
  const tolerance = 0.01; // Acceptable difference from target IRR
  const maxIterations = 50; // Prevent infinite loops
  let iterations = 0;

  while (iterations < maxIterations) {
    iterations += 1;
    const monthlyPayment = (low + high) / 2; // Binary search approach

    // Calculate payments array
    const paymentsArray = [amountToFinance * -1];
    for (let i = 1; i < months; i += 1) {
      paymentsArray.push(monthlyPayment);
    }
    // Add final payment with residual
    const residualAmount =
      discountedAmount * (residualPercentage / 100) +
      monthlyPayment -
      upfrontPaymentAmount;
    paymentsArray.push(residualAmount);

    const calculatedIrr = IRR(paymentsArray, 0.045);
    const annualIRR = +(calculatedIrr * 12 * 100).toFixed(2);
    const diff = Math.abs(annualIRR - targetIRR);

    // If we're within tolerance, we've found our answer
    if (diff <= tolerance) {
      return monthlyPayment;
    }

    // Keep track of best result in case we don't find exact match
    if (diff < bestDiff) {
      bestDiff = diff;
      bestMonthlyPayment = monthlyPayment;
    }

    // Binary search adjustment
    if (annualIRR > targetIRR) {
      high = monthlyPayment;
    } else {
      low = monthlyPayment;
    }
  }

  // Return best approximation if exact solution not found
  return bestMonthlyPayment;
};
