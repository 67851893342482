import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { pdf } from '@react-pdf/renderer';
import * as Yup from 'yup';
import {
  ErcoInvoice,
  ErcoProduct,
  FactorInformation,
  FactorInformationVals,
  FactorSheet,
  ProductConditions,
} from '../../interfaces/products';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import DialogCustomTitle from '../../../../components/DialogCustomTitle/DialogCustomTitle';
import CenteredForm from '../../../../index.styled';
import { PrimarySubtitle } from '../../../../components/PrimarySubtitle/PrimarySubtitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { DisclaimerText } from '../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import NoImage from '../../../../assets/img/no-image.jpg';
import EmptyClosure from '../../../../utils/closures';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import {
  regularPaymentOptions,
  thirtyFiveResidualOptions,
} from '../../interfaces/businessLogic';
import InvoicePrintable from './InvoicePrintable';
import { getBase64 } from '../../../../utils/fns';
import { FlexContainer } from '../../../../components/FlexContainer/FlexContainer';
import {
  MinAmountMessage,
  MinValidationMessage,
  RequiredMessage,
} from '../../../../utils/validationMessages';
import { IRR } from '../../../../utils/money';
import { calculateMonthlyPayment } from '../../../../utils/calculateTIR';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: ErcoInvoice;
  product: ErcoProduct;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: ErcoInvoice = {
  id: 0,
  is_active: true,
  fecha: '',
  productId: 0,
  discountAmount: 0,
  discountPercentage: 0,
  upfrontPaymentAmount: 0,
  upfrontPaymentPercentage: -1,
  twelveMonths: false,
  twentyFourMonths: false,
  thirtySixMonths: false,
  fortyEightMonths: false,
  sixtyMonths: false,
  twelveResidual: 0,
  twentyFourResidual: 0,
  thirtySixResidual: 0,
  fortyEightResidual: 0,
  sixtyResidual: 0,
  customerName: '',
  customerFirstLastName: '',
  customerSecondLastName: '',
  customerEmail: '',
  customerPhone: '',
  pdfURL: '',
  commission: 2,
  tir: 30,
  supervisorEmail: '',
};

function ErcoProductForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
  product,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    handleLogoutError,
    token,
  } = useCotizadorProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState<string>('');
  const [factorSheet, setFactorSheet] = useState<FactorSheet>();
  const [thirtyFiveResidual, setThirtyFiveResidual] = useState<boolean>(false);
  const residualDisabled = useMemo(() => thirtyFiveResidual, [thirtyFiveResidual]);
  const [accountInfo, setAccountInfo] = useState<{
    isAdmin: boolean;
    supervisorEmail?: string;
  }>({ isAdmin: false });

  useEffect(() => {
    const fetchIsAdmin = async () => {
      try {
        const fetchResult = await axios.get<{
          isAdmin: boolean;
          supervisorEmail?: string;
        }>(`${process.env.REACT_APP_SERVER_URL}/erco/account`, {
          headers: { 'access-token': token ?? '' },
        });
        setAccountInfo(fetchResult.data);
      } catch (e) {
        setAccountInfo({ isAdmin: false });
      }
    };
    fetchIsAdmin().then(EmptyClosure).catch(EmptyClosure);
  }, [token]);

  useEffect(() => {
    if (values) {
      setFormValues({ ...values, supervisorEmail: accountInfo?.supervisorEmail });
    } else {
      setFormValues({ ...initialValues, supervisorEmail: accountInfo?.supervisorEmail });
    }
  }, [values, accountInfo]);

  const residualYup = (key: keyof ErcoInvoice) =>
    Yup.number().when(key, {
      is: true,
      then: (sch) =>
        sch.when('upfrontPaymentPercentage', (upfrontPaymentPercentage: number) => {
          if (upfrontPaymentPercentage && upfrontPaymentPercentage > 0) {
            return Yup.number()
              .required(RequiredMessage)
              .min(
                Number(upfrontPaymentPercentage ?? 0),
                MinAmountMessage(upfrontPaymentPercentage ?? 0),
              )
              .required(RequiredMessage);
          }
          return Yup.number();
        }),
    });

  const validationSchema = Yup.object({
    upfrontPaymentPercentage: Yup.number()
      .min(1, MinAmountMessage(1))
      .required(RequiredMessage),
    twelveResidual: residualYup('twelveMonths'),
    twentyFourResidual: residualYup('twentyFourMonths'),
    thirtySixResidual: residualYup('thirtySixMonths'),
    fortyEightResidual: thirtyFiveResidual
      ? Yup.number()
      : residualYup('fortyEightMonths'),
    sixtyResidual: thirtyFiveResidual ? Yup.number() : residualYup('sixtyMonths'),
    tir: Yup.number().required(RequiredMessage),
    commission: Yup.number().required(RequiredMessage),
    supervisorEmail: Yup.string().email('Escriba un email válido').nullable(),
    customerEmail: Yup.string().email('Escriba un email válido').nullable(),
  });

  const findFactorSheet = (form: ErcoInvoice): FactorSheet => {
    const discountAmount =
      Number(form.discountPercentage) > 0
        ? (product.price * Number(form.discountPercentage)) / 100
        : 0;
    const paymentOptions = (
      thirtyFiveResidual ? thirtyFiveResidualOptions : regularPaymentOptions
    )
      .filter((opt) => opt.upfrontPayment === (form.upfrontPaymentPercentage || 0) / 100)
      .sort((a, b) => a.months - b.months);
    const discountedAmount = product.price - (discountAmount ?? 0);
    const registrationCost = 2500;
    const commission = discountedAmount * (form.commission / 100);
    const iva = (commission + registrationCost + (form.upfrontPaymentAmount ?? 0)) * 0.16;
    const finalInitialPayment =
      commission + registrationCost + (form.upfrontPaymentAmount ?? 0) + iva;
    const amountToFinance = discountedAmount / 1.16 - (form.upfrontPaymentAmount ?? 0);
    const residualKeys: (keyof ErcoInvoice)[] = [
      'twelveResidual',
      'twentyFourResidual',
      'thirtySixResidual',
      'fortyEightResidual',
      'sixtyResidual',
    ];
    const factorInformation = residualKeys.reduce(
      (acc: Partial<FactorInformation>, current, index): Partial<FactorInformation> => {
        if (!form[current.replace('Residual', 'Months') as keyof ErcoInvoice]) {
          return {
            ...acc,
            [String(12 * (index + 1))]: {
              factor: 0,
              defaultResidual: 0,
              monthlyPayment: 0,
              residualAmount: 0,
            },
          };
        }
        const defaultValues: FactorInformationVals = {
          factor: paymentOptions[index].factor, // 0-1 value
          defaultResidual: paymentOptions[index].residualAmount, // 0-1 value
          monthlyPayment: paymentOptions[index].factor * discountedAmount, // decimal amount
          residualAmount:
            discountedAmount * paymentOptions[index].residualAmount + // valor residual inicial
            paymentOptions[index].factor * discountedAmount -
            (form.upfrontPaymentAmount ?? 0), // decimal amount
        };
        let factorInfromationVals: FactorInformationVals = { ...defaultValues };
        if (
          (!thirtyFiveResidual &&
            defaultValues.defaultResidual !== Number(form[current]) / 100) ||
          form.tir !== 30
        ) {
          const newMonthlyPayment = calculateMonthlyPayment(
            amountToFinance,
            discountedAmount,
            Number(form[current]),
            form.upfrontPaymentAmount ?? 0,
            12 * (index + 1),
            form.tir, // You can now pass different target IRR values here
          );

          factorInfromationVals = {
            ...factorInfromationVals,
            factor: newMonthlyPayment / discountedAmount,
            monthlyPayment: newMonthlyPayment,
            residualAmount:
              discountedAmount * (Number(form[current]) / 100) + // valor residual inicial
              newMonthlyPayment -
              (form.upfrontPaymentAmount ?? 0), // decimal amount
          };
        }
        return {
          ...acc,
          [String(12 * (index + 1))]: factorInfromationVals,
        };
      },
      {},
    );
    return {
      discountedPrice: discountedAmount,
      initialPayment: {
        registrationCost,
        commission,
        iva,
        finalInitialPayment,
      },
      amountToFinance,
      factorInformation: factorInformation as FactorInformation,
    };
  };

  const formik = useFormik<ErcoInvoice>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (productValues) => {
      try {
        const finalForm: ErcoInvoice = {
          ...productValues,
          discountAmount:
            Number(productValues.discountPercentage) > 0
              ? (product.price * Number(productValues.discountPercentage)) / 100
              : 0,
          discountPercentage: Number(productValues.discountPercentage),
          twelveResidual: Number(productValues.twelveResidual),
          twentyFourResidual: Number(productValues.twentyFourResidual),
          thirtySixResidual: Number(productValues.thirtySixResidual),
          fortyEightResidual: Number(productValues.fortyEightResidual || 0),
          sixtyResidual: Number(productValues.sixtyResidual || 0),
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          upfrontPaymentAmount,
        };
        const sheet = findFactorSheet(finalForm);
        setFactorSheet(factorSheet);
        setError('');
        setLoading(true);
        if (
          ![
            productValues.twelveMonths,
            productValues.twentyFourMonths,
            productValues.thirtySixMonths,
            productValues.fortyEightMonths,
            productValues.sixtyMonths,
          ].includes(true)
        ) {
          setError('Debes seleccionar al menos una opción de mensualidad a cotizar');
        }
        if (!factorSheet) {
          setError('Error al generar la cotización, intente de nuevo');
        }

        const blob = await pdf(
          InvoicePrintable({
            invoice: finalForm,
            product,
            factorSheet: sheet,
          }),
        ).toBlob();
        const transformedFile = await getBase64(blob);
        const res = await axios.post<ErcoInvoice>(
          `${process.env.REACT_APP_SERVER_URL}/erco/${
            mode === CRUDModes.Create ? 'save' : 'update'
          }-invoice`,
          {
            invoice: {
              ...finalForm,
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              productId: product.id,
            },
            file: transformedFile,
          },
          {
            headers: { 'access-token': token ?? '' },
          },
        );
        setLoading(false);
        setAlertType(AlertTypes.Success);
        setAlertMessage(`Información guardada con éxito`);
        setOpenAlert(true);
        console.log(res);
        await formik.setFieldValue('pdfURL', res.data.pdfURL);
        setError('');
      } catch (e: any) {
        console.log(e);
        setFactorSheet(undefined);
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar producto: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const resetConditions = async () => {
    await formik.setFieldValue('twelveMonths', false);
    await formik.setFieldValue('twentyFourMonths', false);
    await formik.setFieldValue('thirtySixMonths', false);
    await formik.setFieldValue('fortyEightMonths', false);
    await formik.setFieldValue('sixtyMonths', false);
    await formik.setFieldValue('twelveResidual', 0);
    await formik.setFieldValue('twentyFourResidual', 0);
    await formik.setFieldValue('thirtySixResidual', 0);
    await formik.setFieldValue('fortyEightResidual', 0);
    await formik.setFieldValue('sixtyResidual', 0);
    await formik.setFieldValue('upfrontPaymentPercentage', -1);
    await formik.setFieldValue('upfrontPaymentAmount', 0);
  };

  const upfrontPaymentAmount = useMemo(() => {
    const creditAmount = formik.values.discountPercentage
      ? product.price - (product.price * formik.values.discountPercentage) / 100
      : product.price;
    if (
      formik.values.upfrontPaymentPercentage &&
      formik.values.upfrontPaymentPercentage > 0
    ) {
      return (creditAmount * formik.values.upfrontPaymentPercentage) / 100;
    }
    return 0;
  }, [
    formik.values.discountPercentage,
    formik.values.upfrontPaymentPercentage,
    product.price,
  ]);

  const findDefaultResidual = (months: number, upfrontPaymentPercentage: number) => {
    const paymentOptions = regularPaymentOptions.filter(
      (opt) =>
        opt.upfrontPayment === (upfrontPaymentPercentage || 0) / 100 &&
        months === opt.months,
    );
    return (paymentOptions[0]?.residualAmount ?? 0) * 100;
  };

  useEffect(() => {
    const setResiduals = async () => {
      if (thirtyFiveResidual) {
        await formik.setFieldValue('twelveResidual', 35);
        await formik.setFieldValue('twentyFourResidual', 35);
        await formik.setFieldValue('thirtySixResidual', 35);
        await formik.setFieldValue('fortyEightResidual', 35);
        await formik.setFieldValue('sixtyResidual', 35);
      } else if (formik.values.upfrontPaymentPercentage) {
        await formik.setFieldValue(
          'twelveResidual',
          findDefaultResidual(12, formik.values.upfrontPaymentPercentage),
        );
        await formik.setFieldValue(
          'twentyFourResidual',
          findDefaultResidual(24, formik.values.upfrontPaymentPercentage),
        );
        await formik.setFieldValue(
          'thirtySixResidual',
          findDefaultResidual(36, formik.values.upfrontPaymentPercentage),
        );
        await formik.setFieldValue(
          'fortyEightResidual',
          findDefaultResidual(48, formik.values.upfrontPaymentPercentage),
        );
        await formik.setFieldValue(
          'sixtyResidual',
          findDefaultResidual(60, formik.values.upfrontPaymentPercentage),
        );
      }
    };
    setResiduals().then(EmptyClosure).catch(EmptyClosure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.upfrontPaymentPercentage, thirtyFiveResidual]);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='Cotización'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <FormikProvider value={formik}>
            <CenteredForm onSubmit={formik.handleSubmit}>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item xs={12}>
                  <PrimarySubtitle mobile>Cotizar producto</PrimarySubtitle>
                  <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                    Cotiza un arrendamiento a partir de los datos del producto guardado
                  </PrimaryDescription>
                </Grid>
                {!factorSheet ? (
                  <>
                    <Grid item xs={12} md={4} lg={3}>
                      <img
                        src={product.imageURL || NoImage}
                        alt='Imagen del producto'
                        style={{
                          width: '100%',
                          aspectRatio: '1 / 1',
                          objectFit: 'cover',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Producto:</b> {product.name}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Descripción:</b> {product.description}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Accesorios:</b> {product.accesories}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Garantía:</b> {product.warranty}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Estado:</b>{' '}
                        {product.condition === ProductConditions.new ? 'Nuevo' : 'Usado'}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Precio:</b>{' '}
                        {product.price -
                          (formik.values.discountPercentage
                            ? (product.price * formik.values.discountPercentage) / 100
                            : 0)}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Modelo:</b> {product.model}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Año:</b> {product.year}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Tiempo de entrega:</b> {product.timeForShipping}
                      </PrimaryDescription>
                    </Grid>
                    <Grid item xs={12}>
                      <PrimarySubtitle style={{ margin: '1rem 0 1rem 0' }} mobile>
                        Términos de arrendamiento
                      </PrimarySubtitle>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='set35'
                            id='set35'
                            checked={thirtyFiveResidual}
                            onChange={(e) => {
                              setThirtyFiveResidual(e.target.checked);
                              resetConditions().then(EmptyClosure).catch(EmptyClosure);
                            }}
                          />
                        }
                        label={
                          <PrimaryDescription style={{ margin: 0, fontWeight: 500 }}>
                            ¿Aplicar valor residual fijo del 35%?
                          </PrimaryDescription>
                        }
                      />
                    </Grid>
                    {accountInfo.isAdmin && !thirtyFiveResidual && (
                      <>
                        <Grid item xs={12} md={6}>
                          <CMTextfield
                            id='tir'
                            label='TIR'
                            type='number'
                            placeholder='TIR (en porcentaje)'
                            value={formik.values.tir || null}
                            name='tir'
                            onChange={async (e) => {
                              if (e.target.value) {
                                await formik.setFieldValue(
                                  'tir',
                                  Number(e.target.value),
                                  true,
                                );
                              } else {
                                await formik.setFieldValue('tir', 30, false);
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CMTextfield
                            id='commission'
                            label='Comisión'
                            type='number'
                            placeholder='Comisión (en porcentaje)'
                            value={formik.values.commission || null}
                            name='commission'
                            onChange={async (e) => {
                              if (e.target.value) {
                                await formik.setFieldValue(
                                  'commission',
                                  Number(e.target.value),
                                  true,
                                );
                              } else {
                                await formik.setFieldValue('commission', 2, false);
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='discountPercentage'
                        label='Porcentaje de descuento'
                        type='number'
                        placeholder='Porcentaje de descuento'
                        value={formik.values.discountPercentage || null}
                        name='discountPercentage'
                        onChange={async (e) => {
                          if (e.target.value) {
                            await formik.setFieldValue(
                              'discountPercentage',
                              Number(e.target.value),
                              true,
                            );
                          } else {
                            await formik.setFieldValue('discountPercentage', 0, false);
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='discountAmount'
                        label='Monto descuento'
                        type='number'
                        placeholder='Monto descuento'
                        value={
                          formik.values.discountPercentage
                            ? (product.price * formik.values.discountPercentage) / 100
                            : 0
                        }
                        name='discountAmount'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        select
                        id='upfrontPaymentPercentage'
                        label='Porcentaje de anticipo'
                        value={String(formik.values.upfrontPaymentPercentage)}
                        name='upfrontPaymentPercentage'
                        style={{ marginBottom: '1rem' }}
                        error={
                          formik.touched.upfrontPaymentPercentage &&
                          Boolean(formik.errors.upfrontPaymentPercentage)
                        }
                        helperText={
                          formik.touched.upfrontPaymentPercentage &&
                          formik.errors.upfrontPaymentPercentage
                        }
                        onChange={formik.handleChange}
                      >
                        <MenuItem value='-1' disabled>
                          Seleccione una opción
                        </MenuItem>
                        <MenuItem value='10'>10%</MenuItem>
                        <MenuItem value='15'>15%</MenuItem>
                        <MenuItem value='20'>20%</MenuItem>
                        <MenuItem value='25'>25%</MenuItem>
                        <MenuItem value='30'>30%</MenuItem>
                        <MenuItem value='35'>35%</MenuItem>
                        {!thirtyFiveResidual && <MenuItem value='40'>40%</MenuItem>}
                        {!thirtyFiveResidual && <MenuItem value='45'>45%</MenuItem>}
                        {!thirtyFiveResidual && <MenuItem value='50'>50%</MenuItem>}
                      </CMTextfield>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='upfrontPaymentAmount'
                        label='Monto anticipo'
                        placeholder='Monto anticipo'
                        value={upfrontPaymentAmount}
                        name='upfrontPaymentAmount'
                        onChange={formik.handleChange}
                        disabled
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PrimarySubtitle style={{ margin: '0 0 1rem 0' }} mobile>
                        Plazos a cotizar
                      </PrimarySubtitle>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='twelveMonths'
                            id='twelveMonths'
                            checked={formik.values.twelveMonths}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <PrimaryDescription style={{ margin: 0 }}>
                            12 meses
                          </PrimaryDescription>
                        }
                      />
                      <CMTextfield
                        id='twelveResidual'
                        label='% Valor residual'
                        placeholder='% Valor residual'
                        value={formik.values.twelveResidual || ''}
                        type='number'
                        name='twelveResidual'
                        onChange={formik.handleChange}
                        disabled={residualDisabled}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.twelveResidual &&
                          Boolean(formik.errors.twelveResidual)
                        }
                        helperText={
                          formik.touched.twelveResidual && formik.errors.twelveResidual
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='twentyFourMonths'
                            id='twentyFourMonths'
                            checked={formik.values.twentyFourMonths}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <PrimaryDescription style={{ margin: 0 }}>
                            24 meses
                          </PrimaryDescription>
                        }
                      />
                      <CMTextfield
                        id='twentyFourResidual'
                        label='% Valor residual'
                        placeholder='% Valor residual'
                        value={formik.values.twentyFourResidual || ''}
                        type='number'
                        name='twentyFourResidual'
                        onChange={formik.handleChange}
                        disabled={residualDisabled}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.twentyFourResidual &&
                          Boolean(formik.errors.twentyFourResidual)
                        }
                        helperText={
                          formik.touched.twentyFourResidual &&
                          formik.errors.twentyFourResidual
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='thirtySixMonths'
                            id='thirtySixMonths'
                            checked={formik.values.thirtySixMonths}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <PrimaryDescription style={{ margin: 0 }}>
                            36 meses
                          </PrimaryDescription>
                        }
                      />
                      <CMTextfield
                        id='thirtySixResidual'
                        label='% Valor residual'
                        placeholder='% Valor residual'
                        type='number'
                        value={formik.values.thirtySixResidual || ''}
                        name='thirtySixResidual'
                        onChange={formik.handleChange}
                        disabled={residualDisabled}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.thirtySixResidual &&
                          Boolean(formik.errors.thirtySixResidual)
                        }
                        helperText={
                          formik.touched.thirtySixResidual &&
                          formik.errors.thirtySixResidual
                        }
                      />
                    </Grid>
                    {!thirtyFiveResidual && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color='primary'
                                name='fortyEightMonths'
                                id='fortyEightMonths'
                                checked={formik.values.fortyEightMonths}
                                onChange={formik.handleChange}
                              />
                            }
                            label={
                              <PrimaryDescription style={{ margin: 0 }}>
                                48 meses
                              </PrimaryDescription>
                            }
                          />
                          <CMTextfield
                            id='fortyEightResidual'
                            label='% Valor residual'
                            placeholder='% Valor residual'
                            type='number'
                            value={formik.values.fortyEightResidual || ''}
                            name='fortyEightResidual'
                            onChange={formik.handleChange}
                            disabled={residualDisabled}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.fortyEightResidual &&
                              Boolean(formik.errors.fortyEightResidual)
                            }
                            helperText={
                              formik.touched.fortyEightResidual &&
                              formik.errors.fortyEightResidual
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color='primary'
                                name='sixtyMonths'
                                id='sixtyMonths'
                                checked={formik.values.sixtyMonths}
                                onChange={formik.handleChange}
                              />
                            }
                            label={
                              <PrimaryDescription style={{ margin: 0 }}>
                                60 meses
                              </PrimaryDescription>
                            }
                          />
                          <CMTextfield
                            id='sixtyResidual'
                            label='% Valor residual'
                            placeholder='% Valor residual'
                            type='number'
                            value={formik.values.sixtyResidual || ''}
                            name='sixtyResidual'
                            onChange={formik.handleChange}
                            disabled={residualDisabled}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.sixtyResidual &&
                              Boolean(formik.errors.sixtyResidual)
                            }
                            helperText={
                              formik.touched.sixtyResidual && formik.errors.sixtyResidual
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <PrimarySubtitle style={{ margin: '0 0 1rem 0' }} mobile>
                        Datos del cliente
                      </PrimarySubtitle>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='customerName'
                        label='Nombre(s)'
                        placeholder='Nombre(s)'
                        value={formik.values.customerName}
                        name='customerName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerFirstLastName'
                        label='Apellido paterno'
                        placeholder='Apellido paterno'
                        value={formik.values.customerFirstLastName}
                        name='customerFirstLastName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerSecondLastName'
                        label='Apellido materno'
                        placeholder='Apellido materno'
                        value={formik.values.customerSecondLastName}
                        name='customerSecondLastName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='customerEmail'
                        label='Email cliente'
                        placeholder='Email cliente'
                        value={formik.values.customerEmail}
                        name='customerEmail'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerPhone'
                        label='Teléfono cliente'
                        placeholder='Teléfono cliente'
                        value={formik.values.customerPhone}
                        name='customerPhone'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {accountInfo.isAdmin && (
                        <CMTextfield
                          id='supervisorEmail'
                          label='Email supervisor'
                          placeholder='Email supervisor'
                          value={formik.values.supervisorEmail}
                          name='supervisorEmail'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    </Grid>
                  </>
                ) : null}
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <Grid item xs={12}>
                  {formik.values.pdfURL ? (
                    <>
                      <PrimarySubtitle mobile>Comparte la cotización</PrimarySubtitle>
                      <FlexContainer
                        direction={mobile ? 'column' : 'row'}
                        justifyContent={mobile ? 'flex-start' : 'space-around'}
                        style={{
                          gap: mobile ? '1rem' : '0',
                          marginTop: '1.5rem',
                        }}
                      >
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          style={{ backgroundColor: '#0077B5', color: 'white' }}
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.download = 'cotizacion.pdf';
                            a.href = formik.values.pdfURL;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';

                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Descargar
                        </PrimaryButton>
                        <PrimaryButton
                          loading={loading}
                          style={{ backgroundColor: '#25D366', color: 'white' }}
                          type='button'
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.href = `https://api.whatsapp.com/send?text=Hola ${formik.values.customerName}, te envío la cotización del producto que solicitaste. Haz click en el enlace para revisarla: ${formik.values.pdfURL}`;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Enviar por WhatsApp
                        </PrimaryButton>
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          style={{ backgroundColor: '#0077B5', color: 'white' }}
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.href = `mailto:${formik.values.customerEmail}?${
                              formik.values.supervisorEmail
                                ? `bcc=${formik.values.supervisorEmail}&`
                                : ''
                            }subject=Cotización Arrendamiento&body=Hola ${
                              formik.values.customerName
                            }, te envío la cotización del producto ${
                              product.name
                            } que solicitaste. Haz click en el enlace para revisarla: ${
                              formik.values.pdfURL
                            }`;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Enviar correo
                        </PrimaryButton>
                      </FlexContainer>
                    </>
                  ) : null}
                  {!formik.values.pdfURL ? (
                    <PrimaryButton
                      loading={loading}
                      type='submit'
                      style={{ width: '100%' }}
                    >
                      Cotizar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              </Grid>
            </CenteredForm>
          </FormikProvider>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

ErcoProductForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default ErcoProductForm;
